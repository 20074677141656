<template>
  <div>
    <div class="banner_container">
      <el-image
        class="banner"
        :src="require('@as/serve/banner.png')"
      ></el-image>
      <div class="info">
        <div class="title">Hui律界MVP</div>
        <div class="subtitle">
          法律界最有价值专家，简称 MVP（Most Valuable Professional），
          是专注于推动法律行业前行的意见领袖和实践领袖。
        </div>
        <div class="btn">成为MVP成员</div>
      </div>
    </div>
    <div class="menu">
      <a class="item active" href="#member">MVP成员</a>
      <a class="item" href="#event">活动报道</a>
      <a class="item" href="#equity">MVP权益</a>
      <a class="item" href="#task">MVP使命</a>
      <a class="item" href="#apply">申请流程</a>
      <a class="item" href="#issue">相关问题</a>
    </div>
    <div class="section" id="mbmber">
      <div class="title">
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_1.png')"
          alt=""
          srcset=""
        />
        <span>MVP成员</span>
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_2.png')"
          alt=""
          srcset=""
        />
      </div>
      <div class="member content">
        <div class="col">
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
          <div class="card_big">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
          <div class="card_small">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
          <div class="card_big">
            <el-image
              class="img"
              :src="require('@as/serve/card.png')"
            ></el-image>
            <div class="info">
              <div class="name">李青峰</div>
              <div class="post">新浪微博 运营总监</div>
            </div>
          </div>
        </div>
      </div>
      <div class="more" @click="toMember()">查看更多</div>
    </div>
    <div class="section black" id="event">
      <div class="title">
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_1.png')"
          alt=""
          srcset=""
        />
        <span>活动报道</span>
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_2.png')"
          alt=""
          srcset=""
        />
      </div>
      <div class="event content">
        <div class="card">
          <el-image
            class="img"
            :src="require('@as/serve/card_1.png')"
          ></el-image>
          <div class="info">
            <div class="title">别了，化妆品概念性添加</div>
            <div class="detail">
              本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。
            </div>
            <div class="time">10分钟前</div>
          </div>
        </div>
        <div class="card">
          <el-image
            class="img"
            :src="require('@as/serve/card_1.png')"
          ></el-image>
          <div class="info">
            <div class="title">别了，化妆品概念性添加</div>
            <div class="detail">
              本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。
            </div>
            <div class="time">10分钟前</div>
          </div>
        </div>
        <div class="card">
          <el-image
            class="img"
            :src="require('@as/serve/card_1.png')"
          ></el-image>
          <div class="info">
            <div class="title">别了，化妆品概念性添加</div>
            <div class="detail">
              本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。
            </div>
            <div class="time">10分钟前</div>
          </div>
        </div>
        <div class="card">
          <el-image
            class="img"
            :src="require('@as/serve/card_1.png')"
          ></el-image>
          <div class="info">
            <div class="title">别了，化妆品概念性添加</div>
            <div class="detail">
              本文以期对国内医药行业投资者及医药研发企业有所启发和帮助。
            </div>
            <div class="time">10分钟前</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="equity">
      <div class="title">
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_1.png')"
          alt=""
          srcset=""
        />
        <span>MVP权益</span>
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_2.png')"
          alt=""
          srcset=""
        />
      </div>
      <div class="equity content">
        <div class="card" v-for="(item, index) in cardArr" v-bind:key="index">
          <el-image class="card_icon" :src="item.icon" alt="" />
          <div class="name">{{ item.name }}</div>
          <div class="detail">{{ item.detail }}</div>
        </div>
      </div>
    </div>
    <div class="section gray" id="task">
      <div class="title">
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_1.png')"
          alt=""
          srcset=""
        />
        <span>MVP使命</span>
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_2.png')"
          alt=""
          srcset=""
        />
      </div>
      <div class="content task">
        <div class="card">
          <el-image
            class="card_icon"
            :src="require('@as/serve/card_icon_9.png')"
            alt=""
          />
          <div class="name">法律共建专家</div>
          <div class="detail">根据平台提供的法律业务，反馈法律建议</div>
        </div>
        <div class="card">
          <el-image
            class="card_icon"
            :src="require('@as/serve/card_icon_10.png')"
            alt=""
          />
          <div class="name">关键意见领袖</div>
          <div class="detail">
            发布法律相关的原创实务文章，在社区积极回答其他用户的问题
          </div>
        </div>
        <div class="card">
          <el-image
            class="card_icon"
            :src="require('@as/serve/card_icon_11.png')"
            alt=""
          />
          <div class="name">法律布道师</div>
          <div class="detail">
            在公开活动中作为讲师分享专业观点和执业经验；组织法律沙龙或研讨会
          </div>
        </div>
      </div>
    </div>
    <div class="section" id="apply">
      <div class="title">
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_1.png')"
          alt=""
          srcset=""
        />
        <span>申请流程</span>
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_2.png')"
          alt=""
          srcset=""
        />
      </div>
      <div class="content apply">
        <div class="detail">
          法律界最具价值专家，是hui律网颁发给法律专家的一项荣誉认证，以此感谢他们为推动法律的发展所作出的贡献。这些法律专家来自于各个业务领域和行业，他们热衷实践、乐于分享，为法律社区的建设和推动专业领域的传播做出了卓越的贡献。
        </div>
        <div class="flow">
          <div class="item">
            <el-image
              class="flow_icon"
              :src="require('@as/serve/card_icon_12.png')"
              alt=""
              srcset=""
            />
            <div>提交申请</div>
          </div>
          <div class="flow_line"></div>
          <div class="item">
            <el-image
              class="flow_icon"
              :src="require('@as/serve/card_icon_13.png')"
              alt=""
              srcset=""
            />
            <div>提供资料</div>
          </div>
          <div class="flow_line"></div>
          <div class="item">
            <el-image
              class="flow_icon"
              :src="require('@as/serve/card_icon_14.png')"
              alt=""
              srcset=""
            />
            <div>审核资质</div>
          </div>
          <div class="flow_line"></div>
          <div class="item">
            <el-image
              class="flow_icon"
              :src="require('@as/serve/card_icon_15.png')"
              alt=""
              srcset=""
            />
            <div>公布结果</div>
          </div>
        </div>
        <div class="divider">
          <div class="item"></div>
          <div class="item"></div>
        </div>
        <ul class="tag_group">
          <li class="item">
            法律书作者、或参与编写专著、发布原创文章、知名博主等
          </li>
          <li class="item">某业务领域具有影响力的法律专家</li>
          <li class="item">社区领导者、行业大会重量级演讲嘉宾</li>
          <li class="item">热衷为法律的发展传播贡献力量</li>
        </ul>
      </div>
    </div>
    <div class="section gray" id="issue">
      <div class="title">
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_1.png')"
          alt=""
          srcset=""
        />
        <span>相关问题</span>
        <el-image
          class="title_icon"
          :src="require('@as/serve/title_icon_2.png')"
          alt=""
          srcset=""
        />
      </div>
      <div class="content issue">
        <div class="card">
          <div class="name">如何选拔</div>
          <div class="line"></div>
          <div class="detail">
            按上述流程提交报名材料后，hui律网邀请评委将根据您在过去一年中对法律发展、社区、线下峰会的贡献进行审核，最后确定获奖者。
          </div>
        </div>
        <div class="card">
          <div class="name">选拔结果何时公布？</div>
          <div class="line"></div>
          <div class="detail">
            MVP每个季度评选一次，会在每个季度末公布最新MVP名单。如果遗憾落选，可积累更多能体现您资质的材料，重新到MVP平台提交申请。
          </div>
        </div>
        <div class="card">
          <div class="name">MVP有效期多久？</div>
          <div class="line"></div>
          <div class="detail">
            MVP有效期为一年。在此期间，获奖者享有华为云MVP荣誉称号及相应权益；到期后，将根据期间的贡献和成就，重新评估MVP奖项资格
          </div>
        </div>
        <div class="card">
          <div class="name">哪些人可申请MVP？</div>
          <div class="line"></div>
          <div class="detail">
            MVP包括但不限于以下角色：律所主任、律所某业务领域负责人、企业总法、法务总监、高校研究者、学术协会专家、公检法司相关负责人等
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aplly_btn">立即申请</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardArr: [
        {
          icon: require('@as/serve/card_icon_1.png'),
          name: '官方认证布道师',
          detail: 'hui律网严格程序认证领域专家',
        },
        {
          icon: require('@as/serve/card_icon_2.png'),
          name: '产品体验服务',
          detail: '尊享hui律网资源及新产品优先体验权',
        },
        {
          icon: require('@as/serve/card_icon_3.png'),
          name: '个人品牌打造',
          detail: '媒体特邀个人专访、官方媒体渠道露出、法律主流媒体渠道推广',
        },
        {
          icon: require('@as/serve/card_icon_4.png'),
          name: '高端法律圈层',
          detail: '与业内优秀翘楚、大咖同行，拓展人脉',
        },
        {
          icon: require('@as/serve/card_icon_5.png'),
          name: '个人书籍出版',
          detail: '为有书籍出版需求的专家提供优质出版社资源对接',
        },
        {
          icon: require('@as/serve/card_icon_6.png'),
          name: '官方活动主推',
          detail: 'hui律网活动峰会Speaker嘉宾；协助组织论坛沙龙',
        },
        {
          icon: require('@as/serve/card_icon_7.png'),
          name: 'MVP定制礼品',
          detail: '尊享hui律网定制精美礼品，在线下活动等场合不定期发放',
        },
        {
          icon: require('@as/serve/card_icon_8.png'),
          name: '商务合作',
          detail: '优先获得hui律网平台案源，优先获得其他商务合作权等',
        },
      ],
    }
  },
  methods: {
    toMember() {
      this.$router.push({ name: 'member' })
    },
  },
}
</script>

<style lang="less" scoped>
.banner_container {
  position: relative;
  .banner {
    height: 300px;
    display: block;
  }
  .info {
    position: absolute;
    top: 74px;
    left: 400px;
    .title {
      font-size: 48px;
      font-family: zihun143;
      font-weight: normal;
      color: #a38f5d;
    }
    .subtitle {
      margin-top: 24px;
      width: 500px;
      font-size: 16px;
      font-family: zihun59hao;
      font-weight: normal;
      color: #a38f5d;
      line-height: 24px;
    }
    .btn {
      margin-top: 24px;
      padding: 11px 16px;
      display: inline-block;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #a38f5d;
      border: 1px solid #a38f5d;
    }
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: #404040;
  .item {
    margin-left: 96px;
    font-size: 16px;
    color: #cccccc;
    &:first-child {
      margin-left: 0;
    }
    &.active {
      color: #a38f5d;
    }
  }
}
.section {
  padding: 64px 0;
  background: #212121;
  text-align: center;
  &.black {
    background: #101010;
  }
  &.gray {
    background: #2b2b2b;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-family: PingFangSC;
    font-weight: 300;
    color: #a38f5d;
    .title_icon {
      width: 100px;
      height: 11px;
      display: block;
      &:first-child {
        margin-right: 16px;
      }
      &:last-child {
        margin-left: 16px;
      }
    }
  }
  .content {
    margin: 36px auto 0;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    &.member {
      .col {
        width: 290px;
        .card_small {
          margin-top: 15px;
          display: flex;
          width: 100%;
          height: 145px;
          &:first-child {
            margin-top: 0;
          }
          .img {
            width: 145px;
            height: 145px;
            display: block;
          }
          .info {
            padding: 30px 0 30px 16px;
            width: 145px;
            height: 145px;
            text-align: left;
            background: #131313;
            box-sizing: border-box;
            .name {
              font-size: 24px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #ffffff;
            }
            .post {
              margin-top: 16px;
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #ffffff;
              opacity: 0.87;
            }
          }
        }
        .card_big {
          position: relative;
          margin-top: 15px;
          width: 290px;
          height: 305px;
          .img {
            width: 100%;
            height: 100%;
            display: block;
          }
          .info {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 20px 30px;
            width: 100%;
            height: 95px;
            box-sizing: border-box;
            background: #000000;
            opacity: 0.7;
            .name {
              margin-bottom: 16px;
              font-size: 24px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #ffffff;
            }
            .post {
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #ffffff;
              opacity: 0.87;
            }
          }
        }
      }
    }
    &.event {
      flex-wrap: wrap;
      .card {
        display: flex;
        height: 188.8px;
        cursor: pointer;
        &:hover {
          box-shadow: 2px 2px 6px 0 rgba(000, 000, 000, 0.4);
          .info {
            background: #4d4d4d;
          }
        }
        &:nth-child(odd) {
          margin-right: 20px;
        }
        &:nth-child(-n + 2) {
          margin-bottom: 21px;
        }
        .img {
          width: 320px;
          height: 100%;
          display: block;
        }
        .info {
          padding: 37px 29px;
          width: 270px;
          height: 100%;
          text-align: left;
          background: #292929;
          box-sizing: border-box;
          .title {
            font-size: 18px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #ffffff;
            display: block;
          }
          .detail {
            margin: 15px 0 28px 0;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            line-height: 28px;
            color: #999999;
          }
          .time {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #afafaf;
          }
        }
      }
    }
    &.equity {
      flex-wrap: wrap;
      .card {
        padding: 26px 0;
        margin-bottom: 50px;
        width: 240px;
        height: 190px;
        text-align: center;
        box-sizing: border-box;
        background: center/100% 100% url('~@as/serve/card_bg.png') no-repeat;
        &:not(:nth-child(4n)) {
          margin-right: 60px;
        }
        .name {
          margin: 24px 0 16px;
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
        }
        .detail {
          line-height: 24px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #cccccc;
        }
      }
    }
    &.task {
      .card {
        width: 280px;
        text-align: center;
        &:not(:first-child) {
          margin-left: 140px;
        }
        .card_icon {
          width: 40px;
          height: 44px;
        }
        .name {
          margin: 22px 0 16px;
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
        }
        .detail {
          line-height: 24px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #cccccc;
        }
      }
    }
    &.apply {
      flex-direction: column;
      align-items: center;

      .detail {
        margin: 0 auto;
        width: 928px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #cccccc;
        line-height: 24px;
      }
      .flow {
        margin: 39px auto 58px;
        display: flex;
        align-items: center;
        .item {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
          .flow_icon {
            margin-bottom: 12px;
            width: 60px;
            height: 60px;
            display: block;
          }
        }
        .flow_line {
          margin: 0 8px 30px;
          width: 190px;
          height: 1px;
          background: #a38f5d;
          opacity: 0.4;
        }
      }
      .divider {
        margin-bottom: 64px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item {
          width: 330px;
          height: 1px;
          background: #ffffff;
          opacity: 0.1;
        }
      }
      .tag_group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 800px;
        .item {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #cccccc;
          line-height: 24px;
          list-style-type: disc;
          text-align: left;
          &:nth-child(odd) {
            width: 558px;
          }
        }
      }
    }
    &.issue {
      .card {
        padding: 50px 32px;
        width: 280px;
        height: 290px;
        background: #353535;
        box-sizing: border-box;
        .name {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
        }
        .line {
          margin: 18px auto 23px;
          width: 30px;
          height: 2px;
          background: #5e5e5e;
        }
        .detail {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #cccccc;
          line-height: 24px;
        }
      }
    }
  }
  .more {
    margin: 36px auto 0;
    width: 140px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: #a38f5d;
    border: 1px solid #a38f5d;
    border-radius: 17px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .aplly_btn {
    margin: 0 auto;
    width: 200px;
    height: 40px;
    background: linear-gradient(90deg, #baa572, #a38f5d);
    line-height: 40px;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #06232b;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
}
</style>